//CSS
require('../../assets/admin-assets/css/vendors.css');
require('../../assets/admin-assets/vendors/css/forms/icheck/icheck.css');
require('../../assets/admin-assets/vendors/css/forms/icheck/custom.css');
require('../../assets/admin-assets/css/plugins/forms/switch.min.css');
require('../../assets/admin-assets/css/app.css');
require('../../node_modules/daterangepicker/daterangepicker.css');
require('../../assets/admin-assets/css/core/menu/menu-types/vertical-menu.css');
require('../../assets/admin-assets/css/core/colors/palette-gradient.css');
require('../../assets/admin-assets/css/core/colors/palette-callout.min.css');
require('../../assets/admin-assets/vendors/css/pickers/spectrum/spectrum.css');
require('../../assets/admin-assets/vendors/css/forms/selects/selectize.css');
require('../../assets/admin-assets/css/pages/login-register.css');
require('../../node_modules/toastr/build/toastr.min.css');
require('../../node_modules/image-picker/image-picker/image-picker.css');
require('../../assets/admin-assets/css/custom.css');

//JS
require('../../assets/admin-assets/vendors/js/jquery.init.js');
require('../../assets/admin-assets/vendors/js/jquery.js');
require('../../assets/admin-assets/vendors/js/vendors.min.js');
require('../../node_modules/moment/moment.js');
require('../../node_modules/daterangepicker/daterangepicker.js');
require('../../assets/admin-assets/vendors/js/forms/icheck/icheck.min.js');
require('../../assets/admin-assets/js/core/app-menu.js');
require('../../assets/admin-assets/js/core/app.js');
require('../../assets/admin-assets/vendors/js/pickers/spectrum/spectrum.js');
require('../../assets/admin-assets/vendors/js/forms/select/selectize.min.js');
require('../../assets/admin-assets/js/scripts/forms/select/jquery.ddslick.js');
require('../../node_modules/image-picker/image-picker/image-picker.min.js');
require('../../assets/admin-assets/js/scripts/forms/form-login-register.js');
require('../../assets/admin-assets/js/ajax.js');
require('../../assets/admin-assets/js/custom.js');

