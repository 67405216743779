import Swal from 'sweetalert2';
import toastr from "toastr";
import Switchery from "switchery";
import {webalize} from 'webalize';

// TINYMCE IMPORTS
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/skins/lightgray/content.min.css';
import 'tinymce/skins/lightgray/skin.min.css';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/codesample';
 
//END OF IMPORTS --------------------------------------------------------------------


( function($) {
    $(document).ready( function() {


        //potvrdenie zmazania formulara  -------------------------------------------------------
        $('.delete-confirm').on('click', function(e) {
            e.preventDefault();
            var form = $(this).parents('form');
            
            Swal({
              title: 'Smazat?',
              text: "",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Zrušit',
              confirmButtonText: 'Ano, smazat!'
            }).then((result) => {
              if (result.value) {
                form.submit();
              }
            })
          });
        
            
        //load TINYMCE   -------------------------------------------------------
        tinymce.init({
          selector: '.myeditor',
          skin: false, 
          plugins: 'paste, link, lists, code, codesample',
          toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | paste, code, codesample, blockquote',
          menubar: true,
          setup : function(ed) {
            ed.on('change', function(e) {
               // This will print out all your content in the tinyMce box
               //console.log('the content '+ed.getContent());
               // Your text from the tinyMce box will now be passed to your  text area ... 
               $(".myeditor").text(ed.getContent()); 
            });
          }
        });


        //load TOASTER   ---------------------------------------------------------
        if($('.bs-callout-success').length){
            let title = $(this).find('.fm strong').text();
            let message = $(this).find('.fm p').text();
            toastr.success(message, title, {
              "closeButton": true,
              "newestOnTop": true,
              "progressBar": true,
              "positionClass": "toast-top-center"
            });
          };

        if($('.bs-callout-error').length){
          let title = $(this).find('.fm strong').text();
          let message = $(this).find('.fm p').text();
          toastr.error(message, title, {
            "closeButton": true,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-center"
          });
        };


        //change brand logo according to menu status
        $(window).on('resize', function() {
          if ($("body").hasClass("menu-collapsed")) {
            $("img.brand-logo").attr("src","/assets/img/erplogo-small.png");
          }
          else {
            $("img.brand-logo").attr("src","/assets/img/erplogo.png");
          }
        });


        //load DATE PICKER   ---------------------------------------------------------
        $('.datetimepicker').daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          timePicker: true,
          timePicker24Hour: true,
          locale: {
            format: 'DD. MM. YYYY HH:mm'
          }
        })


        //load DDSLICK (selectbox s obrazkami) ------------------------------------
        if ($('select.ddslick option').length > 0) {

          $('#blog-new select.ddslick, #blog-edit select.ddslick').ddslick({
            onSelected: function(selectedData){
                //callback function: do something with selectedData;
                $("#post_thumb input.dd-selected-value").attr('name', 'post[thumb]');
            }   
          });

          $('#references-new select.ddslick, #references-edit select.ddslick').ddslick({
            onSelected: function(selectedData){
                //callback function: do something with selectedData;
                $("#reference_image_min input.dd-selected-value").attr('name', 'reference[image_min]');
            }   
          });

        }

        
        //load IMAGE PICKER   ---------------------------------------------------------
        $('select.image-picker').imagepicker();

        //load IMAGE PICKER   ---------------------------------------------------------
        $(".mycolorpicker").spectrum({
          preferredFormat: "hex",
          showInput: true,
          showPalette: false,
          palette: [["red", "rgba(0, 255, 0, .5)", "rgb(0, 0, 255)"]]
        });
        
        //load SWITCHERY (nice checkbox switch)  ---------------------------------------------------------
        var elem = document.querySelector('.switchery');
        if (elem) {
          var init = new Switchery(elem);
        }

        //load WEBALIZE (JS nice url for slug)-----------------------------------------
        $('.webalize-input').focusout(function() {
          var val = $(this).val();
          if ($('.webalize-output').val() == "") {
            $('.webalize-output').val(webalize(val));
          }
        });


        //load SELECTIZE (nice tag selector)  ---------------------------------------------------------
        $('.input-tags').selectize({
          maxItems: 10
        });



    } );
     } ) ( jQuery );
 