( function($) {
    $(document).ready( function() {

   //check url START -------------------------------------------------------
   $(".ajax-url-check").focusout(function(event){ 
      var url = $(this).val();
      var type = $(this).attr("data-url");

      $.ajax({  
         url:        '/admin/ajax/checkurl',  
         type:       'POST',   
         dataType:   'json',  
         async:      true,
         data:       jQuery.param({ url:url, type:type}),
         
         success: function(data, status) {  
           if (data === false) {
               $(".ajax-url-check-icon").removeClass( "la-question" ).removeClass( "la-times-circle" ).addClass("la-check-square-o" );
            }
            else {
               $(".ajax-url-check-icon").removeClass( "la-question" ).removeClass( "la-check-square-o" ).addClass( "la-times-circle" );
           };
          
         },  
         error: function(xhr, textStatus, errorThrown) {  
            console.log('Ajax request failed.');  
         }  
      });  
   });  
   //check url END -------------------------------------------------------

   
    } );
     } ) ( jQuery );
 